import {
  createStore,
  applyMiddleware,
  compose,
  StoreEnhancerStoreCreator,
} from 'redux';
import { createLogger as reduxLogger } from 'redux-logger';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import schedule from '../modules/schedule/schedule.reducer';

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const rootReducer = combineReducers({
  schedule,
});

// Ref: https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
    __PRELOADED_STATE__?: typeof compose;
  }
}

const configureStore = () => {
  const middleware = [];
  middleware.push(thunk);
  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    middleware.push(
      reduxLogger({
        collapsed: true,
      }),
    );
  }

  const composedEnhancers = compose<StoreEnhancerStoreCreator>(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  // Do we have preloaded state available? Great, save it.
  // eslint-disable-next-line no-underscore-dangle
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    // eslint-disable-next-line no-underscore-dangle
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  return createStore(rootReducer, initialState, composedEnhancers);
};

// Create a store and get back itself and its history object
export const store = configureStore();
